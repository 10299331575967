import { zeroAddress } from './../utils';

const sepoliaAddress = process.env.NEXT_PUBLIC_CONTRACT_TOKEN_SEPOLIA as `0x${string}`;
if (!sepoliaAddress) {
  console.error('NEXT_PUBLIC_CONTRACT_TOKEN_SEPOLIA is not set');
}
const mainnetAddress = process.env.NEXT_PUBLIC_CONTRACT_TOKEN_MAINNET as `0x${string}`;
if (!mainnetAddress) {
  console.error('NEXT_PUBLIC_CONTRACT_TOKEN_MAINNET is not set');
}

function getContractAddress() {
  if (
    !process.env.NEXT_PUBLIC_SKIP_TEST &&
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
  ) {
    return sepoliaAddress || zeroAddress;
  }

  return mainnetAddress || zeroAddress;
}

export default getContractAddress;
