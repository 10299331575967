'use client';
import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import ImmutableTokenABI from 'services/wagmi/immutable-token/abi';
import getImmutableTokenAddress from 'services/wagmi/immutable-token/address';
import getImmutableContractAddress from 'services/wagmi/immutable-collection/address';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import { zeroAddress } from 'viem';

import { getChainID } from 'services/wagmi/utils';

type ImmutableTokenContextType = {
  getUserIMTBalance: {
    data: string;
    bigint: bigint;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  getCollectionAllowance: {
    data: string;
    bigint: bigint;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  approveIMT: {
    approve: () => void;
    isPending?: boolean;
  };
};

const ImmutableTokenContext = createContext<ImmutableTokenContextType>({
  getUserIMTBalance: {
    data: '0.00',
    bigint: 0n,
    isLoading: false,
    isError: false,
    refetch: () => {
      throw new Error('refetch not implemented');
    },
  },
  getCollectionAllowance: {
    data: '0.00',
    bigint: 0n,
    isLoading: false,
    isError: false,
    refetch: () => {
      throw new Error('refetch not implemented');
    },
  },
  approveIMT: {
    approve: () => {
      throw new Error('approve not implemented');
    },
    isPending: false,
  },
});

function ImmutableTokenContextProvider({ children }: PropsWithChildren) {
  const { address } = useAccount();

  const userIMTBalance = useReadContract({
    address: getImmutableTokenAddress(),
    abi: ImmutableTokenABI,
    functionName: 'balanceOf',
    chainId: getChainID(),
    args: [address || zeroAddress],
    query: {
      enabled: !!address,
    },
  });

  const userCollectionAllowance = useReadContract({
    address: getImmutableTokenAddress(),
    abi: ImmutableTokenABI,
    functionName: 'allowance',
    chainId: getChainID(),
    //owner and spender
    args: [address || zeroAddress, getImmutableContractAddress()],
    query: {
      enabled: !!address,
    },
  });

  function getUserIMTBalance() {
    const balance = userIMTBalance.data;
    if (balance) {
      return (+ethers.formatUnits(balance, 18)).toFixed(2);
    } else {
      return '0.00';
    }
  }
  function getUserIMTBalanceBigInt() {
    const balance = userIMTBalance.data;
    if (balance) {
      return balance;
    } else {
      return 0n;
    }
  }

  const {
    writeContract,
    isPending,
    data: hash,
  } = useWriteContract({
    mutation: {
      onSuccess: () => {
        toast.loading('IMT approval sent');
      },
      onError(error) {
        toast.error('Error approving IMT');
        console.error(error);
      },
    },
  });
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (isConfirming) {
      toast.loading('IMT approval confirming');
    }
    if (isConfirmed) {
      toast.success('IMT approved');
      userCollectionAllowance.refetch();
    }
  }, [isConfirmed, isConfirming, userCollectionAllowance]);

  function getCollectionAllowance() {
    const allowance = userCollectionAllowance.data;
    if (allowance) {
      return (+ethers.formatUnits(allowance, 18)).toFixed(2);
    } else {
      return '0.00';
    }
  }

  function getCollectionAllowanceBigInt() {
    const allowance = userCollectionAllowance.data;
    if (allowance) {
      return allowance;
    } else {
      return 0n;
    }
  }

  const contextValue: ImmutableTokenContextType = {
    getUserIMTBalance: {
      data: getUserIMTBalance(),
      bigint: getUserIMTBalanceBigInt(),
      isLoading: userIMTBalance.isLoading,
      isError: userIMTBalance.isError,
      refetch: userIMTBalance.refetch,
    },
    getCollectionAllowance: {
      data: getCollectionAllowance(),
      bigint: getCollectionAllowanceBigInt(),
      isLoading: userCollectionAllowance.isLoading,
      isError: userCollectionAllowance.isError,
      refetch: userCollectionAllowance.refetch,
    },
    approveIMT: {
      isPending,
      approve: () => {
        writeContract({
          address: getImmutableTokenAddress(),
          abi: ImmutableTokenABI,
          functionName: 'approve',
          args: [getImmutableContractAddress(), userIMTBalance.data || 0n],
        });
      },
    },
  };

  const Provider = ImmutableTokenContext.Provider;

  return <Provider value={contextValue}>{children}</Provider>;
}

function useImmutableToken() {
  const context = useContext(ImmutableTokenContext);
  if (!context) {
    throw new Error('useImmutableToken must be used within an ImmutableTokenContextProvider');
  }
  return context;
}

export { ImmutableTokenContextProvider, useImmutableToken };
