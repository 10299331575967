'use client';
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useReadContract, useWriteContract } from 'wagmi';
import ImmutableCollectionABI from 'services/wagmi/immutable-collection/abi';
import getImmutableCollectionAddress from 'services/wagmi/immutable-collection/address';
import { Address } from 'viem';
import { ethers } from 'ethers';
import toast from 'react-hot-toast';
import { getChainID } from 'services/wagmi/utils';

const mintOptionsWhitelist = [1, 3, 5] as const;
const mintOptions = [1, 3, 5, 10] as const;

type ImmutableCollectionContextType = {
  getCurrentPrice: {
    data: string;
    bigint: bigint;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  getMintedSupply: {
    data: number;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  getNFTWhitelistEnabled: {
    data: () => boolean;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
  };
  mintAmount: (typeof mintOptions)[number];
  mintOptions: () => typeof mintOptions | typeof mintOptionsWhitelist;
  onSetMintAmount: (value: (typeof mintOptions)[number]) => void;
  mintNFT: {
    mint: () => void;
  };
};

const ImmutableCollectionContext = createContext<ImmutableCollectionContextType>({
  getCurrentPrice: {
    data: '0.00',
    bigint: 0n,
    isLoading: false,
    isError: false,
    refetch: () => {
      throw new Error('refetch not implemented');
    },
  },
  getMintedSupply: {
    data: 0,
    isLoading: false,
    isError: false,
    refetch: () => {
      throw new Error('refetch not implemented');
    },
  },
  getNFTWhitelistEnabled: {
    data: () => false,
    isLoading: false,
    isError: false,
    refetch: () => {
      throw new Error('refetch not implemented');
    },
  },
  mintAmount: mintOptions[mintOptions.length - 1],
  mintOptions: () => mintOptions,
  onSetMintAmount: () => {
    throw new Error('onSetMintAmount not implemented');
  },
  mintNFT: {
    mint: () => {
      throw new Error('mint not implemented');
    },
  },
});

function ImmutableCollectionContextProvider({ children }: PropsWithChildren) {
  const [mintAmount, setMintAmount] = React.useState<(typeof mintOptions)[number]>(
    mintOptionsWhitelist[mintOptionsWhitelist.length - 1],
  );

  const immutableCollectionAddress = getImmutableCollectionAddress() as Address;

  const currentPriceResult = useReadContract({
    address: immutableCollectionAddress,
    abi: ImmutableCollectionABI,
    functionName: 'getCurrentPrice',
    chainId: getChainID(),
    args: [BigInt(mintAmount)],
    query: {
      refetchOnWindowFocus: true,
    },
  });

  const mintedSupplyResult = useReadContract({
    address: immutableCollectionAddress,
    abi: ImmutableCollectionABI,
    functionName: 'mintedSupply',
    chainId: getChainID(),
    query: {
      refetchOnWindowFocus: true,
    },
  });

  const whitelistEnabledResult = useReadContract({
    address: immutableCollectionAddress,
    abi: ImmutableCollectionABI,
    functionName: 'isWhitelistEnabled',
    chainId: getChainID(),
    query: {
      refetchOnWindowFocus: true,
    },
  });

  const {
    writeContract,
    error,
    isPending,
    data: mintHash,
  } = useWriteContract({
    mutation: {
      onMutate: () => {
        toast.loading('Minting NFT');
      },
      onSuccess: () => {
        toast.success('NFT minted successfully');
        currentPriceResult.refetch();
        mintedSupplyResult.refetch();
      },
    },
  });

  useEffect(() => {
    if (error) {
      console.log('mint error', error);
      if (error.message.includes('Exceeds wallet mint limit')) {
        toast.error('Exceeds Wallet Mint Limit');
      } else if (error.message.includes('Address not whitelisted')) {
        toast.error('Address not whitelisted');
      } else {
        toast.error('Failed to mint NFT');
      }
    }
    if (isPending) {
      toast.dismiss();
      toast.loading('Minting NFT');
    }
  }, [error, isPending]);

  const contextValue: ImmutableCollectionContextType = useMemo(
    () => ({
      getCurrentPrice: {
        data: (function getCurrentPriceData() {
          const currentPrice = currentPriceResult.data;
          if (currentPrice) {
            const currentPriceString = (+ethers.formatUnits(currentPrice, 18)).toFixed(2);
            return currentPriceString;
          } else {
            return '0.00';
          }
        })(),
        bigint: currentPriceResult.data || 0n,
        isLoading: currentPriceResult.isLoading,
        isError: currentPriceResult.isError,
        refetch: currentPriceResult.refetch,
      },
      getMintedSupply: {
        data: (function getMintedSupplyData() {
          const mintedSupply = mintedSupplyResult.data;
          if (mintedSupply) {
            return +ethers.formatUnits(mintedSupply, 0);
          } else {
            return 0;
          }
        })(),
        isLoading: mintedSupplyResult.isLoading,
        isError: mintedSupplyResult.isError,
        refetch: mintedSupplyResult.refetch,
      },
      getNFTWhitelistEnabled: {
        data: function getNFTWhitelistEnabledData() {
          const whitelistEnabled = whitelistEnabledResult.data;
          if (whitelistEnabled) {
            return whitelistEnabled;
          } else {
            return false;
          }
        },
        isLoading: whitelistEnabledResult.isLoading,
        isError: whitelistEnabledResult.isError,
        refetch: whitelistEnabledResult.refetch,
      },
      mintAmount,
      mintOptions: function computeMintOptions(): typeof mintOptions | typeof mintOptionsWhitelist {
        return (function getNFTWhitelistEnabledData() {
          const whitelistEnabled = whitelistEnabledResult.data;
          if (whitelistEnabled) {
            return whitelistEnabled;
          } else {
            return false;
          }
        })()
          ? mintOptionsWhitelist
          : mintOptions;
      },
      onSetMintAmount: function onSetMintAmount(value: (typeof mintOptions)[number]) {
        setMintAmount(value);
      },
      mintNFT: {
        mint: () =>
          writeContract({
            address: immutableCollectionAddress,
            abi: ImmutableCollectionABI,
            functionName: 'buyNFT',
            args: [BigInt(mintAmount)],
          }),
      },
    }),
    [
      mintAmount,
      writeContract,
      immutableCollectionAddress,
      currentPriceResult,
      mintedSupplyResult,
      whitelistEnabledResult,
    ],
  );

  const Provider = ImmutableCollectionContext.Provider;

  return <Provider value={contextValue}>{children}</Provider>;
}

function useImmutableCollection() {
  const context = useContext(ImmutableCollectionContext);
  if (!context) {
    throw new Error('useImmutableCollection must be used within an ImmutableCollectionContextProvider');
  }
  return context;
}

export { ImmutableCollectionContextProvider, useImmutableCollection };
